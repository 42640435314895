import React from 'react';
import banner from "../img/dehesas-con-futuro-171123.jpg";
import footer from "../img/footer-dehesas.jpg";
import "../styles.css";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" alt="Dehesas con futuro, entorno, conservación y aprovechamiento" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="alert alert-success centrado" role="alert">
						<h5>Dehesas con futuro, entorno, conservación y aprovechamiento</h5>
						<h7><strong>Fecha y horario:</strong> 17 de noviembre de 2023, de 9:00 a 14:00</h7><br></br>
						<h7><strong>Lugar:</strong> Edificio multiusos CIPÉREZ (Salamanca)</h7>
						<hr></hr>
						<strong>Objetivos de la Jornada:</strong>
						<p>La jornada está dedicada al ecosistema de la dehesa, donde se tratarán temas como la sostenibilidad ambiental de la dehesa, nuevos retos y oportunidades, desafíos para su gestión y conservación sus recursos agro-silvo-pastoriles</p>
						<p><strong>Enfocado a:</strong>Ganaderos de dehesa</p>
					</div>
				</div>
			</div>
			<div className="card text-white bg-success w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
					<a href="#form"><p><strong>RESERVA TU PLAZA</strong></p>
        			</a>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card border-secondary w-100 caracteristicas izquierda">
					<div className="card-body">
						<h5 className="card-title centrado">Agenda:</h5>
						<li><strong>09:00 - 09:10 Apertura</strong>
						</li>
						<li><strong>09:10 - 09:15 Inauguración</strong>
						</li>
						<li><strong>09:15 - 09:40 "Regeneración de la dehesa"</strong><br></br>
							<i>Juan María Cruz-Sagredo García de MALLA FORESTAL</i>
						</li>
						<li><strong>09:40 - 10:05 "Sanidad vegetal, la invasión del Cerambyx"</strong><br></br>
							<i>Sergio Fernández Alonso de FERRO CONSULTORES</i>
						</li>
						<li><strong>10:05 - 10:30 "Cómo las plantas aromáticas y medicinales pueden contribuir a nuestra dehesa"</strong><br></br>
							<i>María Virginia Ozcariz de ITAGRA</i>
						</li>
						<li><strong>10:30 - 10:45 Coloquio</strong>
						</li>
						<li><strong>10:45 - 11:00 Pausa café</strong>
						</li>
						<li><strong>11:00 - 11:25 “Conservación de la dehesa. Actuaciones de recuperación en el monte de utilidad pública número 138 en El Cubo de Don Sancho”</strong><br></br>
							<i>Cesar Prieto Gil, Jefe de SECCIÓN TERRITORIAL DE GESTIÓN FORESTAL III de la Consejería de Medio Ambiente, Vivienda y Ordenación del Territorio de la Junta de Castilla y León</i>
						</li>
						<li><strong>11:25 - 11:50 “Ayudas y certificación forestal”</strong><br></br>
							<i>Olga González Raposo de FAFCyLE</i>
						</li>
						<li><strong>11:50 - 12:20 Mesa redonda con todos los ponentes</strong><br></br>
						</li>
						<li><strong>12:20 - 13:00 Showcooking y degustación micológica</strong><br></br>
						</li>
						<li><strong>13:00 - 14:00 Visita a la Dehesa “Rollanejo” en El Cubo de Don Sancho</strong>
						</li>
					</div>
					</div>
				</div>
			</div>
			<div className="card text-white bg-secondary border-secondary w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title">Fecha y horario:</h5>
					<p className="card-text-left"> 17 de noviembre de 2023, de 9:00 a 14:00</p>
					<p className="card-text-left"> Edificio multiusos CIPÉREZ (Salamanca)</p>
				</div>
			</div>
			<div className="row inscripcion" id="formulario">
				<div className="col-12">
					<div className="alert alert-light text-center" role="alert" id="form">
						<h3>Formulario de inscripción</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/1061" width="95%" height="900px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<img src={footer} className="banner" alt="Aplicaciones de la robótica e inteligencia artificial en el sector agroalimentario" />
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					Más información <i className="fas fa-phone-square-alt"></i>
					&nbsp;979 10 83 03&nbsp;
					<i className="fas fa-paper-plane"></i>&nbsp;info@itagra.com
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					<a href="https://www.itagra.com">Itagra CT</a>
				</div>
			</div>
		</div>
)

export default Home